import { Component, Input } from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  selector: 'kb-spinner',
  standalone: true,
    imports: [
        NgClass
    ],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.css'
})
export class SpinnerComponent {
    @Input() classes = ''
}
