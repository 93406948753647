import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShippingRestriction } from '../../../common/utils/get-shipping-restrictions';
import { NgIf } from '@angular/common';

@Component({
    selector: 'kb-shipping-info-label',
    template: `
        <div class="border border-red-800 rounded p-4 text-center bg-red-50" *ngIf="shippingInfo?.warning">
            <div class="text-red-800 font-medium">{{ shippingInfo.warning }}</div>
            <div class="text-sm text-gray-600" *ngIf="shippingInfo.description">
                {{ shippingInfo.description }}
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class ShippingInfoLabelComponent {
    @Input() shippingInfo?: ShippingRestriction;
}
