import { CustomerShippingLocation } from '../generated-types';

export interface ShippingRestriction {
    warning: string;
    description?: string;
}

export function getShippingRestrictions(
    defaultShippingLocation: CustomerShippingLocation | undefined,
    shippingRestrictions: string[] | undefined,
) {
    if (shippingRestrictions?.includes('uk-europe') && defaultShippingLocation?.isOutsideEU) {
        return {
            warning: `This product cannot be shipped outside the EU`,
            description: `Due to restrictions from our supplier, we are not able to ship this item to ${
                defaultShippingLocation?.countryName || 'your location'
            }`,
        };
    }
    if (shippingRestrictions?.includes('uk-only') && defaultShippingLocation?.countryCode !== 'GB') {
        return {
            warning: `This product cannot be shipped outside the UK`,
            description: `Due to restrictions such as size or composition, we are not able to ship this item to ${
                defaultShippingLocation?.countryName || 'your location'
            }`,
        };
    }
    if (shippingRestrictions?.includes('no-usa') && defaultShippingLocation?.countryCode === 'US') {
        return {
            warning: `This product cannot be shipped to the USA`,
            description: `Due to restrictions from our supplier, we are not able to ship this item to the USA`,
        };
    }
}
